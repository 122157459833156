<template>
  <ContentBookHeroSection />

  <ContentCarClasses />

  <ContentPamentMethodsSection />

  <ContentMission />

  <section class="py-20">
    <h2 id="services" class="mb-10 text-center">Services</h2>
    <div class="container grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
      <NuxtLink v-for="service in services" :to="service.to" class="flex gap-1 overflow-hidden border rounded-lg shadow hover:shadow-lg sm:flex-col">
        <div
          class="self-stretch w-20 bg-cover sm:w-auto sm:h-40 bg-slate-300"
          :style="{ backgroundImage: `url(${service.image})`, backgroundPosition: service.imagePosition }"
        />
        <div class="self-center p-5 grow">
          <h3 class="max-sm:text-lg max-sm:mb-0">{{ service.title }}</h3>
          <p class="max-sm:hidden">{{ service.description }}</p>
        </div>
      </NuxtLink>
    </div>
  </section>

  <ContentReviewsSection />

  <Footer />
</template>

<script setup lang="ts">
const services = useServicesPages()
</script>
