<template>
  <Head>
    <Link rel="preload" href="/images/welcoming-driver-wide.webp" as="image" />
  </Head>

  <NavBar />

  <div class=" sm:bg-[url(/images/welcoming-driver-wide.webp)] sm:bg-slate-600 bg-cover sm:bg-[60%_50%] md:bg-[60%_50%] lg:bg-[30%_50%] xl:bg-[0%_50%]">
    <div class="flex items-center gap-10 sm:container max-sm:flex-col min-h-[34rem]">
      <div class="max-sm:pt-52 max-sm:w-full py-16 max-sm:px-5 text-center font-serif text-white grow max-sm:bg-[url(/images/welcoming-driver-wide.webp)] max-sm:bg-slate-600 bg-[35%_0] min-[420px]:bg-[30%_0] bg-cover">
        <h1 class="text-2xl font-semibold uppercase md:text-4xl drop-shadow-dark">
          Your personal driver
        </h1>
        <p class="mt-6 text-lg leading-8 text-gray-100 drop-shadow-dark">
          English-speaking local drivers.
        </p>
        <p class="drop-shadow-dark">Chauffeur services. We are based in&nbsp;Prague,&nbsp;Czechia.</p>
      </div>
      <ClientOnly>
        <CheckoutRouteForm class="bg-white rounded dark:bg-black sm:shadow-2xl sm:ring-slate-600 sm:ring-1" />
      </ClientOnly>
    </div>
  </div>
</template>
